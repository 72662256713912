import {db} from '../api/firebase';
import {
  doc,
  getDoc,
  QuerySnapshot,
  collection,
  deleteDoc,
  setDoc,
  getDocs,
  query,
  where,
} from 'firebase/firestore';
import {
  getFunctions,
  connectFunctionsEmulator,
  httpsCallable,
} from 'firebase/functions';
import {getApp} from 'firebase/app';

const functions = getFunctions(getApp());
//connectFunctionsEmulator(functions, "localhost", 5001);

const FirestoreManager = {
  checkCustomerID: async (email, customerID) => {
    try {
      const checkID = httpsCallable(functions, 'auth-checkCustomerID');
      const idValid = await checkID({email, customerID});

      return idValid.data;
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  addBillAccount: async (username, password, uid) => {
    try {
      const login = httpsCallable(functions, 'bill-login');
      const response = await login({username, password});
      console.log(response);

      const {response_status, response_message, response_data} = response.data;
      console.log(response_status);
      if (response_status === 0) {
        console.log(uid);
        const userRef = doc(db, 'users', uid);
        await setDoc(
          userRef,
          {bill: {username: username, password: password}},
          {merge: true},
        );
        return true;
      } else {
        window.alert(response_data.error_message);
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  getPlaidKeys: async () => {
    try {
      const keyRef = doc(db, 'apiKeys', 'plaid');
      const credentials = await getDoc(keyRef);
      if (credentials.exists()) {
        return credentials.data();
      } else {
        // doc.data() will be undefined in this case
        return null;
      }
    } catch (err) {
      throw err;
    }
  },
  getAccounts: async (uid, ins) => {
    try {
      const accountQuery = query(
        collection(db, 'users', uid, 'accounts'),
        where('item_id', '==', ins),
      );
      const accounts = getDocs(accountQuery).then(querySnapshot => {
        return querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
      });

      return accounts;
    } catch (err) {
      throw err;
    }
  },
  getInsByItemID: async item_id => {
    try {
      const ins = query(
        collection(db, 'plaid'),
        where('item_id', '==', item_id),
      );
      const instituions = getDocs(ins).then(querySnapshot => {
        return querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
      });
      return instituions;
    } catch (err) {
      console.log(err);
    }
  },
  getInstitutions: async uid => {
    try {
      const ins = query(collection(db, 'plaid'), where('uid', '==', uid));
      const instituions = getDocs(ins).then(querySnapshot => {
        return querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
      });
      return instituions;
    } catch (err) {
      throw err;
    }
  },
  addPlaidAccount: async (account, uid, item_id, access_token) => {
    try {
      account.item_id = item_id;
      account.access_token = access_token;
      if (account.ignore == undefined) {
        if (account.type == 'depository' || account.type == 'credit') {
          account.disable = false;
        } else {
          account.disable = true;
        }
      }
      setDoc(doc(db, 'users', uid, 'accounts', account.account_id), account);
      return;
    } catch (err) {
      console.log(err);
    }
  },
  getUserObject: async uid => {
    try {
      const userRef = doc(db, 'users', uid);
      const user = await getDoc(userRef);
      if (user.exists()) {
        return user.data();
      } else {
        // doc.data() will be undefined in this case
        return null;
      }
    } catch (err) {
      console.log(err);
    }
  },
  setUserObject: async (uid, obj) => {
    try {
      await setDoc(doc(db, 'users', uid), obj, {merge: true});
    } catch (err) {
      console.log(err);
    }
  },
  setPlaidItem: async (item_id, item) => {
    try {
      await setDoc(doc(db, 'plaid', item_id), item, {merge: true});
    } catch (err) {
      throw err;
    }
  },
  removePlaidItems: async (uid, access_token) => {
    try {
      const ins = query(
        collection(db, 'plaid'),
        where('access_token', '==', access_token),
      );
      const insQuerySnapshot = await getDocs(ins);
      insQuerySnapshot.forEach(async document => {
        await deleteDoc(doc(db, 'plaid', document.id));
      });
      const acc = query(
        collection(db, 'users', uid, 'accounts'),
        where('access_token', '==', access_token),
      );
      const accQuerySnapshot = await getDocs(acc);
      accQuerySnapshot.forEach(async document => {
        await deleteDoc(doc(db, 'users', uid, 'accounts', document.id));
      });
    } catch (err) {}
  },
};

export default FirestoreManager;
