import React from 'react';
import Button from '@mui/material/Button';
import style from '../../styles.css';

function AuthFormFooter({ processClick, buttonText, blueText, blueTextUrl, whiteText, processKeyPress, disabled }) {
  return (
      <>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 30}}>
            <button style={{width: '120px', height: '40px'}} disabled={disabled}  onClick={processClick}>{buttonText}</button>
        </div>
        <div style={{ textAlign: 'center', marginTop: '10px', fontSize: '12px' }}>
            {whiteText} <a style={{ marginLeft: '9px', fontWeight: 600, textTransform: 'uppercase', color: 'white'}} href={blueTextUrl}>{blueText}</a>
        </div>
    </>
  );
}

export default AuthFormFooter;
