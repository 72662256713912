import FirestoreManager from '../api/firebaseManager';
import createDataContext from './createDataContext';

const authReducer = (state, action) => {
  switch (action.type) {
      case 'getUserDoc':
        return {...state, userDoc: action.payload}
    default:
      return state;
  }
};


const getUserDoc = dispatch => async (uid) => {
  try {
    const doc = await (FirestoreManager.getUserObject(uid))
    console.log(doc)
    dispatch({type: 'getUserDoc', payload: doc})
  } catch (err){
    console.log(err)
  }
}
const setUserDoc = dispatch => async (uid, obj) => {
  try {
    await (FirestoreManager.setUserObject(uid, obj))
    const doc = await (FirestoreManager.getUserObject(uid))
    dispatch({type: 'getUserDoc', payload: doc})
  } catch (err){
    console.log(err)
  }
}
const defaultValues = {
  userDoc: null,
};

export const {Provider, Context} = createDataContext(
  authReducer,
  {
    getUserDoc,
    setUserDoc,
  },
  defaultValues,
);
