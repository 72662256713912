import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import {PlaidUpdater} from '../../components/Plaid/PlaidLinker';
import {Context as PlaidContext} from '../../contexts/PlaidContext';
import '../../styles/plaid.css';
import {useNavigate} from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import {Context as AuthContext} from '../../contexts/AuthContext';
import Spinner from 'react-activity/dist/Spinner';
import 'react-activity/dist/Spinner.css';
import {TextField} from '@mui/material';
import {deleteField} from 'firebase/firestore';

export default function Dashboard({user}) {
  const navigate = useNavigate();

  const {
    state: {userDoc},
    setUserDoc,
  } = React.useContext(AuthContext);
  const {
    state: {institutions, linking, accountsMissingInfo, accounts},
    addMissingInfo,
  } = React.useContext(PlaidContext);

  const [accountNumber, setAccountNumber] = React.useState('');
  return (
    <div style={{position: 'fixed', width: window.screen.width}}>
      <Navbar className="navBar" user={user} />
      {linking ? (
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <Spinner size={50} color={'gray'} speed={0.5} style={{top: 300}} />
        </div>
      ) : institutions.length > 0 || (userDoc && userDoc.bill) ? (
        <div className="container">
          <div className="header">
            <u>
              <strong>Institution</strong>
            </u>
            <u>
              <strong>Status</strong>
            </u>
            <u>
              <strong>Accounts</strong>
            </u>
            <u>
              <strong>Action</strong>
            </u>
          </div>
          {userDoc && userDoc.bill == 'enabled' && (
            <div className="insContainer">
              <h2>Bill.com</h2>
              <h2>Active</h2>
              <h2>1</h2>
              <h2
                onClick={() => {
                  setUserDoc(user.uid, {bill: deleteField()});
                }}
                style={{color: 'red'}}>
                Remove
              </h2>
            </div>
          )}
          {institutions.map(item => (
            <div
              className="insContainer"
              onClick={() => {
                navigate(`/accounts/${item.item_id}`);
              }}>
              <h2>{item.name}</h2>
              <h2>{item.error == null ? 'Active' : item.error}</h2>
              <h2>{item.accounts}</h2>
              <h2 style={{color: 'blue'}}>More Info</h2>
            </div>
          ))}
          {accountsMissingInfo.length > 0 && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                backgroundColor: 'black',
                opacity: 0.5,
                height: window.screen.height,
                width: window.screen.width,
                zIndex: 0,
              }}></div>
          )}
          {accountsMissingInfo.length > 0 &&
            accountsMissingInfo.map(account => (
              <div
                style={{
                  justifyContent: 'center',
                  width: window.screen.width,
                  height: window.screen.height,
                  display: 'flex',
                }}>
                <div
                  style={{
                    height: 300,
                    width: 400,
                    marginLeft: -150,
                    alignItems: 'center',
                    opacity: 1,
                    backgroundColor: 'white',
                    zIndex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: -200,
                  }}>
                  <h2 style={{textAlign: 'center', fontSize: 16}}>
                    <strong>Please Confirm Account Number</strong>
                  </h2>
                  <h2 style={{marginBottom: 0, textAlign: 'center'}}>
                    {account.name}
                  </h2>
                  <TextField
                    id="confirmPassword"
                    label="Confirm Account Number (Last 4 Digits)"
                    style={{
                      background: 'white',
                      width: '80%',
                      alignSelf: 'center',
                      marginTop: 50,
                    }}
                    value={accountNumber}
                    type="text"
                    onChange={evt => {
                      if (accountNumber.length < 4) {
                        setAccountNumber(evt.target.value);
                      }
                    }}
                    variant="filled"
                    placeholder="Confirm Account Number (Last 4 Digits)"
                  />
                  {user && (
                    <button
                      onClick={() => {
                        if (user.uid) {
                          addMissingInfo(
                            user.uid,
                            account,
                            accountNumber,
                            accountsMissingInfo,
                            accounts,
                          );
                          setAccountNumber('');
                        }
                      }}
                      style={{
                        zIndex: 1,
                        backgroundColor: '#316b3b',
                        width: '150px',
                        height: '40px',
                        border: '0px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        top: 50,
                      }}>
                      <p
                        style={{
                          color: 'white',
                          fontSize: 14,
                          padding: '0px',
                          alignSelf: 'center',
                        }}>
                        Confirm
                      </p>
                    </button>
                  )}
                </div>
              </div>
            ))}
        </div>
      ) : (
        <div style={{textAlign: 'center'}}>
          <h3>Please Link Accounts</h3>
        </div>
      )}
    </div>
  );
}
