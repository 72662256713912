import React from 'react';

function CustomForm({ submitHandler, children }) {
    const keyPress = (e) => {
        if (e.key === "Enter" && submitHandler) {
          e.preventDefault();
          submitHandler();
        }
      }

  return (
        <div onKeyPress={keyPress}>
            {children}
        </div>
  );
}

export default CustomForm;
