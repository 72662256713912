import React from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import Collapse from '@mui/material/Collapse';
import FirestoreManager from '../../api/firebaseManager';
import { Alert } from '@mui/material';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { db } from "../../api/firebase";
import { doc, setDoc } from "firebase/firestore";
import AuthLeft from "../../components/Auth/AuthLeft";
import AuthFormFooter from "../../components/Auth/AuthFormFooter";
import CustomForm from "../../components/Forms/CustomForm";
import { useNavigate } from "react-router-dom";
import Navbar from '../../components/Navbar/Navbar';

function Signup() {
  const navigate = useNavigate();
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState('');
  const [fullName, setFullName] = React.useState("");
  const [customerID, setCustomerID] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [processingSignup, setProcessingSignup] = React.useState();

  const processSignup = async () => {
    const auth = getAuth();
    setProcessingSignup(true);
  
    const idValid = await Promise.resolve(FirestoreManager.checkCustomerID(email, customerID))

    if (idValid == true){
    createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        const user = userCredential.user;

        try {
          await setDoc(doc(db, "users", user.uid), {
            uid: user.uid,
            fullName: fullName,
            emailAddress: email,
            customerID,
            date: new Date(),
          });
          signInWithEmailAndPassword(auth, email, password)
            .then(async (userCredential) => {
              navigate("/dashboard");
            })
            .catch((error) => {
              setError(`Error code ${error.code}: ${error.message}`);
            });
          setProcessingSignup(false);
        } catch (e) {
          setError(e);
          setProcessingSignup(false);
        }
      })
      .catch((error) => {
       setError(`Error code ${error.code}: ${error.message}`);
        setProcessingSignup(false);
      });
    } else {
      setError('Invalid Customer ID')
      setProcessingSignup(false);
    }
  };

  return (
    <div>
       <Navbar className="navBar" />
    <div className="auth-style">
      <Container sx={{ marginTop: "40px", marginBottom: "40px" }} maxWidth="xl">
        <CustomForm submitHandler={processSignup}>
          <Grid container spacing={2}>
            <Grid item lg={6} xs={12}>
              <AuthLeft
                subheading="To receive transactions in the Forest Enter application for your bank accounts, sign up and connect your bank accounts."
              />
            </Grid>
            <Grid item lg={6} xs={12}>
            <Box
                className="custom-form auth-right-wrapper"
                component="form"
                sx={{
                  '& > :not(style)': {
                    width: '100%',
                    height: '600px',
                    backgroundColor: '#336a3e',
                  },
                }}
                noValidate
                autoComplete="off">
                <Grid container spacing={2}>
                  <>
                    <Grid item xs={12}>
                      <FormControl fullWidth></FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <TextField
                         
                          id="customerID"
                          label="Customer ID"
                          style={{background: 'white'}}
                          value={customerID}
                          onChange={(evt) => setCustomerID(evt.target.value)}
                          variant="filled"
                          placeholder="Customer ID"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <TextField
                           id="fullName"
                           label="Name"
                          style={{background: 'white'}}
                          value={fullName}
                          onChange={(evt) => setFullName(evt.target.value)}
                          variant="filled"      
                          placeholder="Name"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          id="email"
                          label="Email"
                          value={email}
                          style={{background: 'white'}}
                          onChange={(evt) => setEmail(evt.target.value)}
                          variant="filled"
                          placeholder="Email"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          id="password"
                          label="Password"
                          value={password}
                          style={{background: 'white'}}
                          onChange={(evt) => setPassword(evt.target.value)}
                          variant="filled"
                          type="password"
                          autoComplete="current-password"
                          placeholder="Confirm Password"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          id="confirmPassword"
                          label="Confirm Password"
                          style={{background: 'white'}}
                          value={confirmPassword}
                          type="password"
                          autoComplete="current-password"
                          onChange={(evt) =>
                            setConfirmPassword(evt.target.value)
                          }
                          variant="filled"
                          placeholder="Confirm Password"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <AuthFormFooter
                        processClick={processSignup}
                        disabled={processingSignup}
                        whiteText="Already have an account?"
                        buttonText={processingSignup ? "Loading..." : "Sign up"}
                        blueText="Log in"
                        blueTextUrl="/login"
                      />
                      <div style={{ marginTop: "187px" }}></div>
                    </Grid>
                  </>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </CustomForm>

      </Container>
<Collapse in={error != ''}>
      <Alert  className='alert' severity="error">{error}</Alert>
      </Collapse>
    </div>
    </div>
  );
}

export default Signup;
