import emptyCheck from '../assets/images/checkboxEmptyGray.png';
import fullCheck from '../assets/images/checkboxBlue.png';
import React from 'react';

import Spinner from "react-activity/dist/Spinner";
export const CheckBox = ({style, value, onPress, setValue}) => {
    const [checkBoxValue, setCheckBoxValue] = React.useState(value)
    const [loading, setLoading] = React.useState(false)
    const [unloading, setUnloading] = React.useState(false)
    if  (value != checkBoxValue){
        return (
            <div>
                <Spinner size='20' color='blue'/>
            </div>
        )
    }
    else if (checkBoxValue) {
        return (
            <div>
                <img onClick={() => {
                    if (value == checkBoxValue)
                    setCheckBoxValue(false)
                    setLoading(false)
                    setUnloading(true)
                    Promise.resolve(setValue(false))

                    }} style={{height: 40, width: 40}}src={fullCheck}/>
            </div>
        )   
    }  else {
        return (
            <div>
                <img onClick={async() => {
                    if (value == checkBoxValue){
                    setCheckBoxValue(true)
                    setLoading(true)
                    setUnloading(false)
                    Promise.resolve(setValue(true))
                    }
                    }} style={{height: 40, width: 40}}src={emptyCheck}/>
            </div>
        )
    }
}