import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import style from '../../styles.css';
import logoImage from '../../assets/images/trees.png';
import authStyle from '../styles/auth-styles.css'

function AuthLeft({ heading, subheading }) {
  return (
            <Box
                className="custom-form auth-left-wrapper"
                component="form"
                sx={{
                  '& > :not(style)': { 
                    width: '100%', 
                    borderRadius: '24px',
                  },
                }}
                noValidate
                autoComplete="off"
              >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className="logo-container">
                          <h1 className="logo-text">{subheading}</h1>
                        </div>
                    </Grid>
                   
                    <Grid item xs={12}>
                    <img className='logo-image' src={logoImage} />
                       
                    </Grid>
                  </Grid>
            </Box>
  );
}

export default AuthLeft
