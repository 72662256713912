import React from 'react';
//import './App.css';
import Signup from './routes/Auth/Signup';
import Login from './routes/Auth/Login';
import {Provider as PlaidProvider} from './contexts/PlaidContext';
import {Provider as  AuthProvider} from './contexts/AuthContext';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import {getAuth} from 'firebase/auth';

import Dashboard from './routes/Plaid/Dashboard';
import AccountPage from './routes/Plaid/AccountPage';


function App() {
  const [showError, setShowError] = React.useState(false);
  const [error, setError] = React.useState('');
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [success, setSuccess] = React.useState('');
  const [hasFetchedUserAuthState, setHasFetchedUserAuthState] =
    React.useState(false);
  const [userAuthState, setUserAuthState] = React.useState();

  const triggerSuccess = msg => {
    setShowSuccess(true);
    setSuccess(msg);
  };

  const triggerError = msg => {
    setShowError(true);
    setError(msg);
  };

  const handleErrorClose = () => {
    setShowError(false);
    setError('');
  };

  const handleSuccessClose = () => {
    setShowSuccess(false);
    setSuccess('');
  };

  const RequireAuth = ({children, redirectTo}) => {
    if (userAuthState === undefined) {
      return <></>;
    }
    return userAuthState ? children : <Navigate to={redirectTo} />;
  };

  // TODO: will this work how we want or will it only set a listener one time
  // thats destroyed once they navgite from the page
  React.useEffect(() => {
    if (!hasFetchedUserAuthState) {
      const auth = getAuth();
      auth.onAuthStateChanged(function (user) {
        if (user) {
          setHasFetchedUserAuthState(true);
          setUserAuthState(user);
          // User is signed in.
        } else {
          // No user is signed in.
          setHasFetchedUserAuthState(true);
          setUserAuthState(false);
        }
      });
    }
  });

  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/signup"
            element={
              <Signup
                showSuccess={triggerSuccess}
                showError={triggerError}
              />
            }
          />
          <Route
            path="/login"
            element={
             
              <Login showSuccess={triggerSuccess} showError={triggerError} />
         
            }
          />
         
          <Route
            path="/dashboard"
            element={
              <RequireAuth redirectTo="/login">
                <Dashboard
                  user={userAuthState}
                  showSuccess={triggerSuccess}
                  showError={triggerError}
                />
             
              </RequireAuth>
            }
          />
           <Route
            path="/accounts/:ins"
            element={
              <RequireAuth redirectTo="/login">
                <AccountPage
                  user={userAuthState}
                  
                />
             
              </RequireAuth>
            }
          />
          <Route
            path="/"
            element={
              <Login showSuccess={triggerSuccess} showError={triggerError} />
            }
          />
          
       
        </Routes>
      </Router>
     
    </>
  );
}

export default () => {
  return (
    <PlaidProvider>
      <AuthProvider>
        <App />
        </AuthProvider>
  </PlaidProvider>
  )
};
