import React from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import {getAuth, signInWithEmailAndPassword} from 'firebase/auth';
import AuthLeft from '../../components/Auth/AuthLeft';
import Collapse from '@mui/material/Collapse';
import AuthFormFooter from '../../components/Auth/AuthFormFooter';
import CustomForm from '../../components/Forms/CustomForm';
import {useNavigate} from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import { Alert } from '@mui/material';

function Login() {

  const navigate = useNavigate();
   const [email, setEmail] = React.useState('');
   const [password, setPassword] = React.useState('');
   const [error, setError] = React.useState('');
  const [processingLogin, setProcessingLogin] = React.useState();
 

  const processLogin = async () => {
    const auth = getAuth();

    setProcessingLogin(true);

    signInWithEmailAndPassword(
      auth,
      email,
      password,
    )
      .then(async userCredential => {       
          navigate('/dashboard');
          setProcessingLogin(false);
        
      })
      .catch(error => {
       
        setError(`Error code ${error.code}: ${error.message}`);
        setProcessingLogin(false);
      });
  };

  return (
    <div>
    <Navbar className="navBar" />
    <div className="auth-style">
      
      <Container sx={{marginTop: '40px', marginBottom: '40px'}} maxWidth="xl">
        <CustomForm submitHandler={processLogin}>
       
          <Grid container spacing={2}>
            <Grid item lg={6} xs={12}>
              <AuthLeft
                subheading="Welcome back!"
         
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <Box
                className="custom-form auth-right-wrapper"
                component="form"
                sx={{
                  '& > :not(style)': {
                    width: '100%',
                    height: '550px',
                    backgroundColor: '#336a3e',
                  
                  },
                }}
                noValidate
                autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h4" className="form-title">
                      Log in
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                    <TextField
                        id="email"
                        style={{background: 'white'}}
                        label="Email"
                        value={email}
                        onChange={evt => {
                          setEmail(evt.target.value);
                        }}
                        variant="filled"
                        placeholder="Email"
                        type="text"
                      />
                    
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                  
                      <TextField
                      style={{background: 'white'}}
                        id="password"
                        label="Password"
                        value={password}
                        onChange={evt => {
                          setPassword(evt.target.value);
                        }}
                        variant="filled"
                        placeholder="Password"
                        type="password"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{marginTop: '25px'}}></div>
                    <AuthFormFooter
                      processClick={processLogin}
                      disabled={processingLogin}
                      whiteText="Not yet registered?"
                      buttonText={processingLogin ? 'Logging in...' : 'Log in'}
                      blueText="Sign up"
                      blueTextUrl="/signup"
                    />
                    <div style={{marginTop: '415px'}}></div>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </CustomForm>
      </Container>
      <Collapse in={error != ''}>
      <Alert className='alert' severity="error">{error}</Alert>
      </Collapse>
    </div>
    </div>
  );
}

export default Login;
