import React, {useState} from 'react';
import PlaidManager from '../../api/plaidManager';
import {PlaidLink, usePlaidLink} from 'react-plaid-link';
import {
  getFunctions,
  connectFunctionsEmulator,
  httpsCallable,
} from 'firebase/functions';
import {getApp} from 'firebase/app';
import {Context as PlaidContext} from '../../contexts/PlaidContext';
const functions = getFunctions(getApp());
//connectFunctionsEmulator(functions, "localhost", 5001);

export const PlaidLinker = React.memo(
  ({children, onSuccess, onExit, style, uid}) => {
    const {
      state: {accounts, institutions},
      addPlaidInstitution,
      fetchInitialFromDB,
      setLinking,
      addPlaidAccounts,
    } = React.useContext(PlaidContext);

    const [token, setToken] = React.useState('null');

    let isMounted = true;
    const getToken = async isMounted => {
      try {
        // if there is no user or no plaidManager

        const getToken = httpsCallable(functions, 'plaid-getLinkToken');
        const linkToken = await getToken(uid);
        if (isMounted) {
          if (linkToken != undefined) {
            setToken(linkToken.data);
          }
        }
      } catch (err) {
        console.error(err);
        //TODO: handle
      }
    };

    React.useEffect(() => {
      getToken(isMounted);
      fetchInitialFromDB(uid);
      return () => (isMounted = false);
    }, []);

    return (
      <PlaidLink
        token={token}
        style={{background: 'none', border: 'none', padding: 0}}
        onExit={exit => {
          console.log(exit);
        }}
        onSuccess={async (public_token, metadata) => {
          getToken(isMounted);
          setLinking(true);
          try {
            // TODO: why doesnt this work??
            //check for duplicate institutions
            let uniqueAccount = true;
            const inst_id = metadata.institution.institution_id;
            institutions.forEach(institution => {
              if (institution.institution_id === inst_id) {
                uniqueAccount = false;
              }
            });
            // check for duplicate accounts
            accounts.forEach(currentAccount => {
              metadata.accounts.forEach(newAccount => {
                if (
                  currentAccount.mask === newAccount.mask &&
                  currentAccount.name === newAccount.name
                ) {
                  uniqueAccount = false;
                }
              });
            });
            if (!uniqueAccount) {
              window.alert(
                "It looks like you have already linked that account! Try waiting if you don't see it yet or link a different institution.",
              );
              return;
            }

            const {access_token, item_id} =
              await PlaidManager.exchangePublicToken(public_token);

            try {
              const {name, primary_color, url, logo, oauth} =
                await PlaidManager.getInstitutionWithMetaData(
                  metadata.institution.institution_id,
                );

              const item = {
                uid: uid,
                access_token,
                item_id,
                institution_id: metadata.institution.institution_id,
                name,
                primary_color,
                url,
                logo,
                oauth,
              };
              await addPlaidAccounts(access_token, uid, item_id);
              await addPlaidInstitution(item_id, item, uid);
              setLinking(false);
            } catch (err) {
              console.error(err);
              const item = {
                uid: uid,
                access_token,
                item_id,
                institution_id: metadata.institution.id,
              };
              await addPlaidAccounts(access_token, uid, item_id);
              await addPlaidInstitution(item_id, item, uid);
              setLinking(false);
            }
          } catch (err) {
            setLinking(false);
            console.log(err);
          }
        }}>
        {children ? children : <h2>Connect a bank account</h2>}
      </PlaidLink>
    );
  },
);
export const PlaidUpdater = React.memo(
  ({children, onSuccess, uid, onExit, access_token, item_id}) => {
    const {addPlaidInstitution} = React.useContext(PlaidContext);

    const [token, setToken] = React.useState('null');
    let isMounted = true;

    const getToken = async isMounted => {
      try {
        // if there is no user or plaid manager
        if (!uid) {
          return;
        }
        if (isMounted) {
          const getToken = httpsCallable(functions, 'plaid-getUpdateToken');
          const data = {uid, access_token};
          const linkToken = await getToken(data);
          if (linkToken != undefined) {
            setToken(linkToken.data);
          }
        }
      } catch (err) {
        console.error(err);
        //TODO: handle
      }
    };
    React.useEffect(() => {
      getToken(isMounted);
      return () => (isMounted = false);
    }, []);

    return (
      <PlaidLink
        token={token}
        style={{background: 'none', border: 'none', padding: 0}}
        onExit={exit => {
          console.log('EXIT', exit);
        }}
        onSuccess={() => {
          getToken(isMounted);
          try {
            window.alert(
              'Updating your account was successful. Your transactions and balances will start updating again.',
            );
            addPlaidInstitution(item_id, {error: null});
          } catch (err) {
            //TODO: how handle?
            console.error(err);
          }
          onSuccess();
        }}>
        {children ? children : <h2>Update account</h2>}
      </PlaidLink>
    );
  },
);
