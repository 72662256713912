import React from 'react';
import {useParams} from 'react-router-dom';
import FirestoreManager from '../../api/firebaseManager';
import Navbar from '../../components/Navbar/Navbar';
import {PlaidUpdater} from '../../components/Plaid/PlaidLinker';
import {Context as PlaidContext} from '../../contexts/PlaidContext';
import '../../styles/plaid.css';
import {useNavigate} from 'react-router-dom';
import {Hidden} from '@mui/material';
import {CheckBox} from '../../components/Inputs';

export default function AccountPage({user}) {
  const {ins} = useParams();
  const {
    state: {accounts, currentIns, accountsMissingInfo},
    getAccounts,
    getCurrentIns,
    addMissingInfo,
    setLinking,
    removeItem,
  } = React.useContext(PlaidContext);
  const navigate = useNavigate();

  React.useEffect(() => {
    getAccounts(user.uid, ins);
    getCurrentIns(ins);
  }, []);

  return (
    <div>
      <Navbar className="navBar" user={user} />

      <div className="container" style={{top: 0}}>
        <div className="header" style={{width: '1200px', marginLeft: 0}}>
          <u>
            <strong>Bank</strong>
          </u>
          <u>
            <strong>Type</strong>
          </u>
          <u>
            <strong>Mask</strong>
          </u>
          <u>
            <strong>Status</strong>
          </u>
          <u>
            <strong>Ignore</strong>
          </u>
          <div>
            {currentIns.error == null ? (
              <button
                className="disconnect"
                onClick={() => {
                  try {
                    setLinking(true);
                    removeItem(
                      user.uid,
                      currentIns.access_token,
                      ins,
                      accounts,
                    );
                    navigate('/dashboard');
                  } catch (err) {
                    console.log(err);
                  }
                }}>
                Disconnect
              </button>
            ) : (
              <PlaidUpdater
                uid={user.uid}
                item_id={currentIns.item_id}
                access_token={currentIns.access_token}>
                <button className="disconnect">Update</button>
              </PlaidUpdater>
            )}
          </div>
        </div>

        <div className="accountContainer">
          {accounts.length > 0
            ? accounts.map(
                item =>
                  (item.disable == false || item.ignore != undefined) && (
                    <div className="account">
                      <h2>
                        {item.name.substring(0, 20)}
                        {item.name.length > 20 && '...'}
                      </h2>
                      <h2>{item.subtype}</h2>
                      <h2>{item.mask ? `#${item.mask}` : 'N/A'}</h2>
                      <h2>{item.error == null ? 'Active' : 'Error'}</h2>
                      <div style={{marginLeft: 0, marginTop: 10}}>
                        <CheckBox
                          value={item.ignore}
                          setValue={value => {
                            item.ignore = value;
                            item.disable = value;
                            addMissingInfo(
                              user.uid,
                              item,
                              item.mask,
                              accountsMissingInfo,
                              accounts,
                            );
                          }}
                        />
                      </div>
                    </div>
                  ),
              )
            : null}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <button
          className="disconnect"
          onClick={() => {
            navigate('/dashboard');
          }}>
          Done
        </button>
      </div>
    </div>
  );
}
