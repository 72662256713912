import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import logoImage from '../../assets/images/forest-logo.png';
import {getAuth, signOut} from 'firebase/auth';
import {useNavigate} from 'react-router-dom';
import { PlaidLinker } from '../Plaid/PlaidLinker';
import { TextField } from '@mui/material';
import FirestoreManager from '../../api/firebaseManager';

import {Context as AuthContext} from '../../contexts/AuthContext'
import { Spinner } from 'react-activity';

function Navbar({user}) {
  const navigate = useNavigate();


  const [screenSize, getDimension] = React.useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight
    })
  }
  
  React.useEffect(() => {
    window.addEventListener('resize', setDimension);
    
    return(() => {
        window.removeEventListener('resize', setDimension);
    })
  }, [screenSize])

  const {
    state: {userDoc},
    getUserDoc,
} = React.useContext(AuthContext);

  const [username, setUsername] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [visible, setVisible] = React.useState(false)
React.useEffect(() => {
  if (user != undefined){
  getUserDoc(user.uid)
  }
}, [])
if (userDoc){
  console.log(userDoc.bill)
}

  const logOut = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        navigate('/');
      })
      .catch(error => {
      });
  };
  return (
    <div style={{position: 'absolute', width: '100%', top: 0, zIndex: 10}}>
      {visible &&

      <div style={{display: 'flex', zIndex: 1, justifyContent: 'center', width: '100%', }}>
      <div style={{height: 500, flexDirection: 'column', width: 350,display: 'flex', backgroundColor: 'green', top: 80, position: 'absolute', alignSelf: 'center'}}>
      <p onClick={() => setVisible(false)} style={{color: 'white', fontSize: 18, padding: '0px', alignSelf: 'flex-end', marginRight: 30}}>X</p>
      <p style={{color: 'white', fontSize: 18, padding: '0px', alignSelf: 'center', marginTop: 50}}>Connect Bill Account</p>
      <TextField
                        id="email"
                        style={{background: 'white', width: '80%',marginTop: 40, alignSelf: 'center'}}
                        label="Email"
                        value={username}
                        onChange={evt => {
                          setUsername(evt.target.value);
                        }}
                        variant="filled"
                        placeholder="Email"
                        type="text"
                      />
                      <TextField
                        style={{background: 'white', width: '80%', marginTop: 20,alignSelf: 'center'}}
                        id="password"
                        label="Token"
                        value={password}
                        onChange={evt => {

                          setPassword(evt.target.value);
                        }}
                        variant="filled"
                        placeholder="Token"
                        type="password"
                      />
               
                    {!loading ?
                       <button onClick={async() => {
                        setLoading(true)
                        const response = await FirestoreManager.addBillAccount(username, password, user.uid)
                        console.log(response)
                        if (response){
                          setVisible(false)
                          setLoading(false)
                          getUserDoc(user.uid)
                        } else {
                          setLoading(false)
                        }
                        }}style={{zIndex: 1, backgroundColor: '#c5c7c6', width: '200px', height: '50px', border: '0px',display: 'flex', alignItems: 'center', justifyContent: 'center', alignSelf: 'center', position: 'absolute',bottom: 30 }}>
                      
                <p style={{color: 'black', fontSize: 18, padding: '0px', alignSelf: 'center'}}>Connect</p>
              </button> : 
              <div style={{display: 'flex', alignSelf:'center', marginTop: 50}}>
              <Spinner size={25} />
              </div>
}
        

      </div>
      </div>

}
    <Box style={{zIndex: 0}}>
      <AppBar position="static" sx={{backgroundColor: '#f9fdfe'}}>
        <Toolbar>
          <div className="logo-container">
            <img
              alt="Finsiteful"
              className="logo-image"
              style={{width: '168px', height: '58px'}}
              src={logoImage}
            />
          </div>
          <div style={{flexGrow: 1}} className="nav-links">
           
          </div>
          <div className="nav-links">
            {user ? (
              <div style={{flexDirection: 'row', display:'flex', width: screenSize.dynamicWidth *.30, justifyContent: 'space-evenly'}}>
             {userDoc && userDoc.bill == "enabled" &&
             <button onClick={() => setVisible(true)} style={{right: 30, zIndex: 1, backgroundColor: '#316b3b', width: '150px', height: '30px', border: '0px',display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
               <p style={{color: 'white', fontSize: 14, padding: '0px', alignSelf: 'center'}}>Connect Bill Account</p>
             </button>
}
               <PlaidLinker uid={user.uid}>
               <button style={{right: 30, zIndex: 1, backgroundColor: '#316b3b', width: '150px', height: '30px', border: '0px',display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
               <p style={{color: 'white', fontSize: 14, padding: '0px', alignSelf: 'center'}}>Connect Account</p>
             </button>
             </PlaidLinker>
              <button style={{right: 30, zIndex: 1, backgroundColor: '#c5c7c6', width: '100px', height: '30px', border: '0px',display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={logOut}>
                <p style={{color: 'black', fontSize: 14, padding: '0px', alignSelf: 'center'}}>Log out</p>
              </button>
              </div>
            ) : (
           
                <h2 style={{color: '#316b3b', right: '15%'}}>Data Feed Management</h2>
            
            )}
          </div>
        </Toolbar>
      </AppBar>
    </Box>
    </div>
  );
}

export default Navbar;
