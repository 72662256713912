import createDataContext from './createDataContext';
import FirestoreManager from '../api/firebaseManager';
import PlaidManager from '../api/plaidManager';
const plaidReducer = (state, action) => {
  switch (action.type) {
    case 'getCredentials':
      return {...state, credentials: action.payload};
    case 'removeItem':
      return {
        ...state,
        accounts: action.payload.accounts,
        institutions: action.payload.institutions,
        linking: action.payload.linking,
      };
    case 'initialFetch':
      return {
        ...state,
        ...action.payload,
      };
    case 'addAccount':
      return {
        ...state,
        accounts: action.payload.accounts,
        accountsMissingInfo: action.payload.accountsMissingInfo,
      };
    case 'setLinking':
      return {...state, linking: action.payload};
    case 'getCurrentIns':
      return {...state, currentIns: action.payload};
    case 'addInstitution':
      return {...state, institutions: action.payload};
    case 'error':
      return {...state, error: action.payload};
    default:
      return state;
  }
};
const fetchInitialFromDB = dispatch => async uid => {
  try {
    const credentials = await Promise.resolve(FirestoreManager.getPlaidKeys());
    const institutions = await Promise.resolve(
      FirestoreManager.getInstitutions(uid),
    );

    const accountsMissingInfo = institutions.map(async ins => {
      const accounts = await Promise.resolve(
        FirestoreManager.getAccounts(uid, ins.item_id),
      );
      var tempMissing = [];
      accounts.map(item => {
        if (item.mask == null) {
          tempMissing.push(item);
        }
      });
      return tempMissing;
    });
    const accountsPromise = await Promise.all(accountsMissingInfo);
    var accountsMissing = [];
    accountsPromise.map(item => {
      console.log(item);
      for (var i = 0; i < item.length; i++) {
        if (item[i] && item[i].mask == null) {
          accountsMissing.push(item[i]);
        }
      }
    });

    const payload = {
      credentials,
      institutions,
      accountsMissingInfo: accountsMissing,
      error: null,
    };
    dispatch({type: 'initialFetch', payload});
  } catch (err) {
    console.error(err);
    dispatch({type: 'error', payload: 'Something went wrong'});
  }
};
const addMissingInfo =
  dispatch => async (uid, account, acctNum, accountsMissingInfo, accounts) => {
    try {
      account.mask = acctNum;
      const objIndex = accounts.findIndex(obj => obj.id == account.id);
      accounts[objIndex] = account;
      Promise.resolve(
        FirestoreManager.addPlaidAccount(
          account,
          uid,
          account.item_id,
          account.access_token,
        ),
      );
      dispatch({
        type: 'addAccount',
        payload: {
          accounts,
          accountsMissingInfo: accountsMissingInfo.filter(function (
            oldaccount,
          ) {
            return oldaccount !== account;
          }),
        },
      });
      return;
    } catch (err) {
      console.log(err);
    }
  };
const getAccounts = dispatch => async (uid, ins) => {
  try {
    var accountsMissingInfo = [];
    const accounts = await Promise.resolve(
      FirestoreManager.getAccounts(uid, ins),
    );
    accounts.map(account => {
      if (account.mask == null) {
        accountsMissingInfo.push(account);
      }
    });
    dispatch({type: 'addAccount', payload: {accounts, accountsMissingInfo}});
  } catch (err) {
    console.log(err);
    dispatch({type: 'error', payload: 'Something went wrong'});
  }
};
const getCurrentIns = dispatch => async item_id => {
  try {
    const ins = await Promise.resolve(FirestoreManager.getInsByItemID(item_id));
    dispatch({type: 'getCurrentIns', payload: ins[0]});
  } catch (err) {
    console.log(err);
    dispatch({type: 'error', payload: 'Something went wrong'});
  }
};
const addPlaidAccounts = dispatch => async (access_token, uid, item_id) => {
  try {
    var missingAccounts = [];
    const newAccounts = (await PlaidManager.addAccount(access_token)).data
      .accounts;
    newAccounts.map(account => {
      if (account.mask != undefined) {
        missingAccounts.push(account);
      }
      FirestoreManager.addPlaidAccount(account, uid, item_id, access_token);
    });
    await FirestoreManager.setPlaidItem(item_id, {
      accounts: newAccounts.length,
    });
    const payload = {
      accounts: await FirestoreManager.getAccounts(uid),
      missingAccounts: missingAccounts,
    };
    dispatch({type: 'addAccount', payload});
  } catch (err) {
    console.log(err);
    dispatch({type: 'error', payload: 'Something went wrong'});
  }
};

const addPlaidInstitution = dispatch => async (item_id, item, uid) => {
  try {
    const customerID = (await FirestoreManager.getUserObject(uid)).customerID;
    item.customerID = customerID;
    await FirestoreManager.setPlaidItem(item_id, item);
    const payload = await FirestoreManager.getInstitutions(uid);

    dispatch({type: 'addInstitution', payload});
  } catch (err) {
    dispatch({type: 'error', payload: 'Something went wrong'});
  }
};

const getPlaidCredentials = dispatch => async () => {
  try {
    const credentials = await Promise.resolve(FirestoreManager.getPlaidKeys());
    dispatch({type: 'getCredentials', payload: credentials});
  } catch (err) {
    dispatch({type: 'error', payload: 'Something went wrong'});
  }
};
const setLinking = dispatch => async value => {
  dispatch({type: 'setLinking', payload: value});
};
const removeItem = dispatch => async (uid, token, item_id, accounts) => {
  try {
    const response = await Promise.resolve(PlaidManager.removeItem(token));

    if (response.error_message != undefined) {
      window.alert(response.error_message);
      return;
    }
    await FirestoreManager.removePlaidItems(uid, token);
    const accounts = await Promise.resolve(
      FirestoreManager.getAccounts(uid, item_id),
    );
    const institutions = await Promise.resolve(
      FirestoreManager.getInstitutions(uid),
    );

    const payload = {
      accounts,
      institutions,
      linking: false,
    };
    dispatch({type: 'removeItem', payload: payload});
  } catch (err) {
    dispatch({type: 'setLinking', payload: false});
  }
};

const defaultValues = {
  credentials: null,
  institutions: [],
  accounts: [],
  accountsMissingInfo: [],
  linking: false,
  currentIns: [],
  token: null,
  error: null,
};

export const {Provider, Context} = createDataContext(
  plaidReducer,
  {
    getPlaidCredentials,
    addPlaidInstitution,
    fetchInitialFromDB,
    getCurrentIns,
    getAccounts,
    removeItem,
    setLinking,
    addPlaidAccounts,
    addMissingInfo,
  },
  defaultValues,
);
